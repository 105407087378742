import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/Layout"
import SEO from '../components/SEO'
import PageHero from '../components/PageHero'
import { Link, graphql } from "gatsby"
import { ImHome } from "react-icons/im"
import { IoRocketOutline } from "react-icons/io5"
import SubscribeForm from "../components/SubscribeForm"
import kebabCase from "lodash/kebabCase"

const TagsPage = ({location, data: {allMarkdownRemark: { group },},}) => (
    <Layout location={location}>
        <SEO title="List of Rokit Media Accessible Blog Post Tags" description="Here is a listing of all Tags describing our posts on the Rokit Media blog. Select a Tag to view all posts within the displayed tag label." />
        <PageHero heading="All Post Tags" />
        <section className="container mx-auto max-w-7xl text-center pt-4">
            <div className="px-8 pt-4">
              <ul className="text-left font-mono text-xs md:text-base">
                <li className="inline-block border-black border-r-2">
                  <Link to="/">
                      <span className="inline-block pr-2"><ImHome /></span>
                      <span className="px-2">Home</span>
                  </Link>
                </li>
                <li className="inline-block">
                  <span className="px-2">Tags</span>
                </li>
              </ul>
            </div>
            <div className="cmscontent docs px-5 md:px-10">
              <h2>All Blog Post Tags</h2>
              <p>Here is a listing of all Tags describing our posts on the Rokit Media blog. Select a Tag to view all posts within the displayed tag label.</p>
              <p>A blog tag is a word or a phrase which can describe a blog post. We use them like attaching labels to the content of our posts. Here we provide a list of our current blog post tags which you can use to identify an area of interest. We try to capture the most appropriate labels when writing our blog posts to provide you with relevant content.</p>
            </div>
            <div className="flex flex-col lg:flex-row">
                {/* Main Content */}
                <div className="p-6 lg:w-2/3">
                    <div className="text-left">

                        <div className="flex flex-wrap justify-center items-center">
                            {group.map((tag, index) => {
                            const alternateTextBox = index %2 === 0
                            return (
                                <Link key={tag.fieldValue} to={`/tags/${kebabCase(tag.fieldValue)}/`} rel="tag">
                                    <h3 className={alternateTextBox? "heading-box-text2 text-2xl md:text-3xl lg:text-4xl p-4" : "heading-box-text3 text-2xl md:text-3xl lg:text-4xl p-4"}>
                                        <span className="pulse">{tag.fieldValue} ({tag.totalCount})</span>
                                    </h3>
                                </Link>
                            )})}
                        </div>
                    </div>
                </div>
                {/* Sidebar */}
                <div className="px-6 pt-6 pb-0 lg:pb-6 w-2/3 lg:w-1/3 flex flex-row lg:flex-col items-start">
                    <div className="lg:sticky top-24">
                        <div className="text-base py-0 lg:py-2 pl-2 lg:pl-0 text-left">
                            <div className="hidden lg:inline-flex items-center"><IoRocketOutline /><p>-Rokit Media</p></div>
                        </div>
                        <p className="text-xs hidden lg:inline-block text-left">Rokit Media is a modern digital web design and development agency based in Bishop's Stortford within the Herts and Essex region of the United Kingdom.</p>
                        <p className="text-xs hidden lg:inline-block text-left">We build Websites and Web Apps the modern way, which delivers blazing fast performance, higher security and cheaper to run.</p>
                        <div className="hidden lg:inline-block">
                            <SubscribeForm formid="subscribe-form-1" />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center lg:hidden">
                    <SubscribeForm formid="subscribe-form-2" />
                </div>
            </div>
        </section>
    </Layout>
    )

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`